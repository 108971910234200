.report-card {
  border-radius: pxToRem(4);
  background-color: #ffffff;
  box-shadow: 0 pxToRem(10) pxToRem(20) 0 rgba(18, 38, 63, 0.03);
  padding: pxToRem(28) pxToRem(48) pxToRem(50) pxToRem(48);

  &__spinner {
    height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &--report-involved {
    height: pxToRem(100);

    &--reporter {
      &__avatar {
        width: pxToRem(100);
        height: pxToRem(100);
        border-radius: 50%;
        color: white;
        margin-right: pxToRem(38);
        font-size: pxToRem(20);
        cursor: pointer;
      }

      &__badge {
        height: pxToRem(17);
        width: pxToRem(66);
        border-radius: pxToRem(4);
        background-color: $blue;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: pxToRem(10);
        font-weight: 500;
      }

      &__reporter {
        color: $blue;
        font-size: pxToRem(12);
        font-weight: 500;
        line-height: pxToRem(18);
        margin-right: pxToRem(56);
      }

      &__name {
        color: $gunmetal;
        font-size: pxToRem(15);
        font-weight: 500;
        line-height: pxToRem(23);
        margin-right: pxToRem(16);
        cursor: pointer;
      }

      &__role {
        color: $gunmetal;
        font-size: pxToRem(13);
        line-height: pxToRem(20);
        margin-right: pxToRem(16);
      }

      &__type {
        color: $description;
        font-size: pxToRem(13);
        line-height: pxToRem(20);
      }

      &__price {
        color: $gunmetal;
        font-size: pxToRem(14);
        letter-spacing: 0;
        line-height: pxToRem(21);
        margin-right: pxToRem(35);

        &__icon {
          color: $blue;
          width: pxToRem(15);
          height: pxToRem(15);
          margin-right: pxToRem(5);
        }
      }

      &__date {
        color: $name-color;
        font-size: pxToRem(12);
        font-weight: 500;
        line-height: pxToRem(18);
        align-self: flex-end;
      }
    }

    &--reported {
      &__avatar {
        width: pxToRem(48);
        height: pxToRem(48);
        border-radius: 50%;
        color: white;
        margin-right: pxToRem(16);
        font-size: pxToRem(18);
        cursor: pointer;
      }
    }
  }

  &--report-case {
    margin-top: pxToRem(49);

    &__header {
      color: $gunmetal;
      font-size: pxToRem(15);
      font-weight: 600;
      line-height: pxToRem(23);
    }

    &__text {
      margin-top: pxToRem(15);
      color: $description;
      font-size: pxToRem(13);
      letter-spacing: 0;
      line-height: pxToRem(32);
    }
  }

  &--session {
    margin-top: pxToRem(47);

    &--row {
      border: pxToRem(1) solid #eff2f7;
      height: pxToRem(45);

      &__label {
        color: #495057;
        font-family: Poppins;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
      }

      &__delimiter {
        border-right: pxToRem(1) solid #eff2f7;
        height: 100%;
      }

      &__value {
        padding-left: pxToRem(72);
        color: $gunmetal;
        font-size: pxToRem(13);
        line-height: pxToRem(20);
      }
    }
  }
}
