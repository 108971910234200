.trainers-list {
  &__mb28 {
    margin-bottom: 28px;
  }

  &__header {
    font-size: pxToRem(19);
    font-weight: 500;
    line-height: pxToRem(29);
    margin-bottom: pxToRem(28);
  }

  &--dropdown {
    padding: pxToRem(16);
    padding-bottom: pxToRem(19);
    width: pxToRem(217);
    font-size: pxToRem(14);

    &__title {
      font-size: pxToRem(14);
      font-weight: 600;
      margin-bottom: pxToRem(20);
    }

    &--checkboxes {
      padding: pxToRem(8);
    }

    &__toggle {
      box-sizing: border-box;
      height: pxToRem(37);
      width: pxToRem(109);
      border: 1px solid #556ee6;
      border-radius: 30px;
      color: #556ee6;
      font-size: pxToRem(13);
      line-height: pxToRem(20);
      text-align: center;
    }

    &__btn {
      box-sizing: border-box;
      height: pxToRem(37);
      border: 1px solid #556ee6;
      border-radius: 30px;
      background:#556ee6 ;
      color: white;
      font-size: pxToRem(13);
      line-height: pxToRem(20);
      text-align: center;
    }

    &__toggle:hover {
      color: #556ee6;
      background-color: rgba($color: #556ee6, $alpha: 0.2);
    }

    &__next-section {
      margin-top: pxToRem(32);
    }
  }
}

.trainer-details {
  &__spinner {
    width: 100%;
    height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--tabs {
    height: pxToRem(65);
    padding: pxToRem(16) pxToRem(70) pxToRem(20) pxToRem(60);

    &__tab {
      max-width: pxToRem(250);

      &__span {
        font-weight: normal;
      }
    }
  }

  &--subcription {
    &__type {
      margin-top: pxToRem(10);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-top: solid pxToRem(2) #f6f6f6;
      border-bottom: solid pxToRem(2) #f6f6f6;
      padding-top: pxToRem(15);
      padding-bottom: pxToRem(15);
    }

    &__date {
      padding-top: pxToRem(15);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: pxToRem(15);
    }

    &__title {
      color: $gunmetal;
      font-size: pxToRem(13);
      font-weight: 600;
      line-height: pxToRem(20);
    }

    &__value {
      color: $gunmetal;
      font-size: pxToRem(13);
      line-height: pxToRem(20);
    }
  }
}

.specializations-card {
  padding: pxToRem(25) pxToRem(20) pxToRem(40) pxToRem(20);

  &__description {
    margin-top: pxToRem(10);
    color: $description;
    font-size: pxToRem(13);
    line-height: pxToRem(20);
  }

  &__header {
    color: $gunmetal;
    font-size: pxToRem(16);
    font-weight: 500;
    line-height: pxToRem(25);
  }
}

.working-history-card {
  padding: pxToRem(20);

  &--history-work {
    margin-top: pxToRem(20);

    &__title {
      color: $gunmetal;
      font-size: pxToRem(13);
      font-weight: 500;
      line-height: pxToRem(20);
    }

    &__summary {
      color: $description;
      font-size: pxToRem(13);
      line-height: pxToRem(20);
    }

    &--row {
      border: pxToRem(1) solid #eff2f7;
      height: pxToRem(45);

      &__label {
        color: #495057;
        font-family: Poppins, serif;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
      }

      &__delimiter {
        border-right: pxToRem(1) solid #eff2f7;
        height: 100%;
      }

      &__value {
        color: $gunmetal;
        font-size: pxToRem(13);
        line-height: pxToRem(20);
      }
    }
  }
}

.government-id-card {
  padding: pxToRem(20) pxToRem(30) pxToRem(50) pxToRem(20);

  &__title {
    color: #49505c;
    font-size: pxToRem(15);
    font-weight: 600;
    line-height: pxToRem(23);
    margin-bottom: pxToRem(10);
  }

  &__spinner {
    height: 60px;
    display: flex;
    align-items: center;
    align-self: center;
  }

  &__link {
    width: 100%;
    border-top: pxToRem(1) solid #f6f6f6;
    padding-top: pxToRem(11);
    margin-top: pxToRem(12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    color: #343a40;
    font-size: pxToRem(14);
    font-weight: 500;
    line-height: pxToRem(21);
    i {
      font-size: pxToRem(24);
    }
    &__icon {
      width: pxToRem(48);
      height: pxToRem(48);
    }
  }

  &__description {
    font-size: pxToRem(12);
    color: $description;
  }
}

.offer-card {
  padding: pxToRem(24);

  &--header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__title {
      margin-right: pxToRem(20);
      color: $gunmetal;
      font-size: pxToRem(23);
      line-height: pxToRem(34);
    }

    &__badge {
      font-size: pxToRem(10);
      font-weight: 500;
      line-height: pxToRem(14);
      height: pxToRem(16);
      display: flex;
      align-content: center;
      justify-content: center;
    }

    &__icon {
      color: #f46a6a;
      cursor: pointer;
      font-size: pxToRem(16);
      height: pxToRem(16);
      width: pxToRem(16);
    }

    .bg-soft-primary {
      background-color: #50a5f1;
    }
  }

  &__type {
    margin-top: pxToRem(10);
    color: $description;
    font-size: pxToRem(13);
    line-height: pxToRem(20);
  }

  &__price {
    color: $gunmetal;
    font-size: pxToRem(14);
    letter-spacing: 0;
    line-height: pxToRem(21);
    margin-right: pxToRem(35);
    margin-top: pxToRem(15);

    i {
      color: $blue;
      width: pxToRem(15);
      height: pxToRem(15);
      margin-right: pxToRem(5);
    }
  }

  &--row {
    border: pxToRem(1) solid #eff2f7;
    height: pxToRem(45);

    &__label {
      color: #495057;
      font-size: pxToRem(13);
      font-weight: 600;
      line-height: pxToRem(20);
      display: flex;
      align-items: center;
      border-right: pxToRem(1) solid #eff2f7;
    }

    &__value {
      color: $gunmetal;
      font-size: pxToRem(13);
      line-height: pxToRem(20);
      display: flex;
      align-items: center;
    }
  }

  &--description {
    margin-top: pxToRem(25);

    &__icon-left {
      cursor: pointer;
    }

    &__icon-bottom {
      cursor: pointer;
      transform: rotate(270deg);
    }

    &__text {
      margin-top: pxToRem(15);
      color: $description;
      font-size: pxToRem(13);
      line-height: pxToRem(32);
    }

    &__title {
      color: $gunmetal;
      font-size: pxToRem(15);
      font-weight: 600;
      line-height: pxToRem(23);
    }
  }
}

.earning-card {
  padding: pxToRem(24) pxToRem(42) 0 pxToRem(23);

  &__total {
    color: $gunmetal;
    font-size: pxToRem(14);
    line-height: pxToRem(21);
    margin-top: pxToRem(8);
    margin-bottom: pxToRem(32);
  }

  &__title {
    color: $gunmetal;
    font-size: pxToRem(15);
    font-weight: 600;
    line-height: pxToRem(23);
  }

  &__filter-button {
    width: pxToRem(128);
  }

  &__filter-button-secondary {
    width: pxToRem(128);
    border: pxToRem(1) solid #f6f6f6;
    color: $gunmetal;
    font-size: pxToRem(13);
    font-weight: 500;
    line-height: pxToRem(20);
  }

  &__spinner {
    width: 100%;
    height: pxToRem(395);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .apexcharts-xaxis-label:nth-last-child(2) {
    transform: translateX(-20px);
  }

  .apexcharts-tooltip-title {
    color: #adb5bd;
    font-size: pxToRem(12);
    line-height: pxToRem(18);
  }
}

.trainer-reviews-list {
  &--header {
    margin-bottom: pxToRem(20);

    &__title {
      color: $gunmetal;
      font-size: pxToRem(20);
      font-weight: 500;
      line-height: pxToRem(29);
    }
  }

  &__review-text {
    max-width: 270px;
  }
}

.trainer-reports-tab {
  &--tabs {
    min-width: pxToRem(225);

    &__tab {
      height: pxToRem(36);

      &__span {
        font-size: pxToRem(13);
        line-height: pxToRem(20);
        font-weight: normal;
      }
    }
  }
}

.no-row-click-table {
  tr {
    cursor: default !important;
  }
}

.board-with-place {
  tbody > tr:nth-child(1) {
    background-color: #fff9e5;
  }
  tbody > tr:nth-child(2) {
    background-color: #fafafa;
  }
  tbody > tr:nth-child(3) {
    background-color: #fff5eb;
  }
}

.first-trainer-board {
  background-color: #fff9e5;
}
.second-trainer-board {
  background-color: #fafafa;
}
.third-trainer-board {
  background-color: #fff5eb;
}
