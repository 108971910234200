.settings-card {
  &--main-card {
    width: pxToRem(451);

    &__form-element {
      max-height: pxToRem(64);
      margin-top: pxToRem(35);
      .form-group {
        input {
          background-image: none;
        }
      }
    }

    &__form-create {
      //max-height: pxToRem(64);
      margin-top: pxToRem(30);
      .form-group {
        input {
          background-image: none;
        }

        legend {
          font-weight: 500;
          margin-bottom: 0.5rem;
          font-size: 13px;
          line-height: 19.5px;
        }
      }
    }

    &__login-button {
      margin-top: pxToRem(47);
      margin-bottom: pxToRem(30);
    }

    &__switch-title {
      color: $gunmetal;
      font-size: pxToRem(14);
      font-weight: 500;
      line-height: pxToRem(21);
    }

    &__switch-section {
      margin-top: pxToRem(70);
    }
  }

  &--modal {
    width: pxToRem(501);

    padding: pxToRem(67) pxToRem(52) pxToRem(61) pxToRem(54);

    &__text {
      color: $gunmetal;
      font-size: pxToRem(16);
      margin-bottom: pxToRem(50);
      margin-top: pxToRem(30);
    }

    &__modal-icon {
      width: pxToRem(92);
      height: pxToRem(81);
    }
  }
}
