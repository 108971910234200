.tutee-list {
  &__header {
    font-size: pxToRem(19);
    font-weight: 500;
    line-height: pxToRem(29);
    margin-bottom: pxToRem(28);
  }

  &--list {
    &__name {
      color: $gunmetal;
      font-size: pxToRem(14);
      font-weight: 500;
      margin-left: pxToRem(38);
    }

    &__badge {
      height: pxToRem(17);
      font-size: pxToRem(12);
      text-align: center;
      padding-left: pxToRem(10);
      padding-right: pxToRem(10);
      border-radius: 1.5rem;
      font-weight: normal;
    }

    &__trainer-name {
      color: $gunmetal;
      font-size: pxToRem(14);
      font-weight: 500;
    }
  }

  &--dropdown {
    padding: pxToRem(16);
    padding-bottom: pxToRem(19);
    width: pxToRem(217);
    font-size: pxToRem(14);

    &__title {
      font-size: pxToRem(14);
      font-weight: 600;
      margin-bottom: pxToRem(20);
    }

    &--checkboxes {
      padding: pxToRem(8);
    }

    &__toggle {
      box-sizing: border-box;
      height: pxToRem(37);
      width: pxToRem(109);
      border: 1px solid #556ee6;
      border-radius: 30px;
      color: #556ee6;
      font-size: pxToRem(13);
      line-height: pxToRem(20);
      text-align: center;
    }
    &__toggle:hover {
      color: #556ee6;
      background-color: rgba($color: #556ee6, $alpha: 0.2);
    }
  }

  &__red {
    font-size: pxToRem(13);
    color: #f46a6a;
  }

  &__green {
    color: #34c38f;
    font-size: pxToRem(13);
  }

  &__yellow {
    font-size: pxToRem(13);
    color: #f1b44c;
  }
}

.tutee-details {
  &__spinner {
    width: 100%;
    height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--tabs {
    height: pxToRem(65);
    padding: pxToRem(16) pxToRem(18) pxToRem(20) pxToRem(20);

    &__tab {
      max-width: pxToRem(250);

      &__span {
        font-weight: normal;
      }
    }
  }

  &--details {
    &__avatar {
      margin-top: pxToRem(-60);
      width: pxToRem(100);
      height: pxToRem(100);
      margin-bottom: pxToRem(19);

      .img-thumbnail {
        object-fit: contain;
        height: pxToRem(100);
        width: pxToRem(100);
      }
    }

    &__button {
      width: pxToRem(100);
      height: pxToRem(27);
    }

    &__button--big {
      width: pxToRem(126);
      height: pxToRem(27);
    }

    &__button-block {
      width: pxToRem(100);
      height: pxToRem(27);
      margin-right: pxToRem(11);
      background-color: #ffffff;
      color: #f46a6a;
    }

    &__button-unblock{
      width: pxToRem(100);
      height: pxToRem(27);
      margin-right: pxToRem(11);
      background-color: #ffffff;
      color: #34c38f;
    } 
  }

  &--info {
    padding: pxToRem(20) pxToRem(25) pxToRem(37) pxToRem(20);

    &--row {
      margin: 0;
      border-bottom: solid pxToRem(2) #eff2f7;
      font-size: pxToRem(13);
      margin-top: pxToRem(8);
      padding-bottom: pxToRem(15);
      padding-top: pxToRem(5);

      &__label {
        font-weight: 600;
      }

      &__value {
        font-weight: normal;
      }

      &__no-border {
        margin: 0;
        font-size: pxToRem(13);
        margin-top: pxToRem(8);
        padding-top: pxToRem(5);
      }
    }
  }

  &--issues {
    padding: pxToRem(37) pxToRem(31) pxToRem(20) pxToRem(32);

    &__title {
      font-size: pxToRem(15);
      font-weight: 600;
      margin-bottom: pxToRem(23);
    }

    &__description {
      color: $description;
      font-size: pxToRem(13);
      line-height: pxToRem(32);
    }
  }
}

.confirm-modal {
  padding: pxToRem(44) pxToRem(49) pxToRem(43) pxToRem(44);

  &__modal-text {
    font-size: pxToRem(16);
    margin-top: pxToRem(24);
    margin-bottom: pxToRem(70);
  }
}

.table-placeholder {
  height: pxToRem(350);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  &__title {
    color: #74788d;
    font-size: pxToRem(16);
    font-weight: 600;
    margin-bottom: pxToRem(38);
  }

  &__image {
    height: pxToRem(172);
    width: pxToRem(176);
  }
}

.react-bs-table-no-data:hover {
  background-color: $white;
  box-shadow: none;
}

.session-comment {
  margin-top: pxToRem(25);

  &__second-section {
    margin-left: pxToRem(65);
  }

  &__title {
    color: $gunmetal;
    font-size: pxToRem(15);
    font-weight: 500;
    line-height: pxToRem(23);
  }

  &__description {
    margin-top: pxToRem(10);
    color: $description;
    font-size: pxToRem(13);
    line-height: pxToRem(20);
  }

  &--date-section {
    margin-top: pxToRem(20);
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $description;
    font-size: pxToRem(12);
    line-height: pxToRem(18);
  }

  &--placeholder {
    height: pxToRem(300);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      margin-top: pxToRem(20);
      color: $description;
      font-size: pxToRem(16);
      font-weight: 600;
      line-height: pxToRem(25);
    }

    &__image {
      margin-top: pxToRem(38);
      height: pxToRem(114);
      width: pxToRem(164);
    }
  }
}

.booking-details {
  &--row {
    &__weekday {
      width: pxToRem(150);
    }

    &__time {
      width: pxToRem(100);
    }

    &__date {
      width: pxToRem(100);
    }
  }
}

.booking-request{
  &__title{
    color: $description;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }

  &__count {
    color: $gray-700;
    font-size: 19.5px;
    font-weight: 500;
    line-height: 29px;
  }
}

.session-info-header{
  margin-bottom: 17px;

  .trainer-name{
    font-weight: 500;
    padding-left: 5px;
  }

  .conducted-sessions{
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    color: #556EE6;
  }

  .no-border-bottom{
    border-bottom: none;
  }
}

.tutee-stars{
  padding-left: 8px;
}