.react-datepicker-wrapper {
  //max-width: 200px;
}

.react-datepicker {
  &__day--selected,
  &__day--in-selecting-range,
  &__day--in-range,
  &__month-text--selected,
  &__month-text--in-selecting-range,
  &__month-text--in-range,
  &__quarter-text--selected,
  &__quarter-text--in-selecting-range,
  &__quarter-text--in-range,
  &__year-text--selected,
  &__year-text--in-selecting-range,
  &__year-text--in-range,
  &__day--keyboard-selected,
  &__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected,
  &__year-text--keyboard-selected {
    background: #556EE6;
    border-radius: 20px;
    &:hover,
    :active {
      background: #556EE6;
      border-radius: 20px;
    }
  }

  &__day:hover,
  &__month-text:hover,
  &__quarter-text:hover,
  &__year-text:hover {
    border-radius: 20px;
    background: rgba(85, 110, 230, 0.1);
  }

  &__input-container {
    input {
      height: 2.3125rem;
      width: 6.8125rem;
      border: 1px solid #556ee6;
      border-radius: 30px;
      color: #556ee6;
      font-size: 0.8125rem;
      line-height: 1.25rem;
      text-align: center;
    }
  }
}

.react-datepicker__month-container + .react-datepicker__month-container {
  margin-left: 20px;
}

.react-datepicker__day.react-datepicker__day--outside-month {
  opacity: 0 !important;
  pointer-events: none;
}
