//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
  + .breadcrumb-item {
    &::before {
      font-family: "Material Design Icons";
    }
  }
}

.custom-breadcrumb {
  font-size: 13px;

  &__item {
    color: $description;
  }

  &__title {
    color: $gunmetal;
  }
}
