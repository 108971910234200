.notifications {
  &--header-hover:hover {
    cursor: pointer;
    background-color: #f8f9fa;
  }

  &--header {
    padding: pxToRem(9) pxToRem(10) pxToRem(9) pxToRem(12);

    &__hash {
      font-size: pxToRem(24);
    }

    &__title {
      color: $gunmetal;
      font-size: pxToRem(13);
      font-weight: 600;
      line-height: pxToRem(20);
    }

    .bg-soft-primary {
      background-color: #50a5f1;
    }
  }

  &__title {
    color: $gunmetal;
    font-size: pxToRem(14);
    font-weight: 500;
    line-height: pxToRem(21);
  }

  &__description {
    color: $description;
    font-size: pxToRem(13);
    letter-spacing: 0;
    line-height: pxToRem(20);
  }

  &__date {
    color: $gunmetal;
    font-size: pxToRem(13);
    letter-spacing: 0;
    line-height: pxToRem(20);
  }

  &__scroll {
    div {
      overflow: hidden;
    }
  }
}

.notification-list-dropdown {
  display: flex;
  flex-direction: row;
  padding-top: pxToRem(15);
  padding-bottom: pxToRem(15);
  padding-left: pxToRem(25);
  padding-right: pxToRem(30);

  &__bar {
    max-height: pxToRem(230);
  }

  &__icon {
    margin-right: pxToRem(15);
    .avatar-sm {
      width: pxToRem(36);
      height: pxToRem(36);
      .icon {
        width: pxToRem(20);
        height: pxToRem(20);
      }
    }
  }

  &--body {
    display: flex;
    flex-direction: column;

    &__title {
      color: $gunmetal;
      font-size: pxToRem(13);
      font-weight: 500;
      line-height: pxToRem(20);
    }

    &__text {
      margin-top: pxToRem(5);
      color: $description;
      font-size: pxToRem(12);
      line-height: pxToRem(18);
    }
  }

  &__date {
    margin-top: pxToRem(7);
    color: $description;
    font-size: pxToRem(10);
    line-height: pxToRem(16);
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      font-size: pxToRem(16);
    }
  }
}

.notification-list-dropdown:hover {
  cursor: pointer;
  background-color: #f8f9fa;
}
