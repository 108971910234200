.modal {
  &--header {
    &__text {
      font-size: pxToRem(22.75);
    }

    &__icon {
      font-size: pxToRem(30);
      position: absolute;
      top: pxToRem(32);
      right: pxToRem(32);
      cursor: pointer;
    }
  }

  &--buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;

    &__button {
      width: pxToRem(187);
      height: pxToRem(37);
    }
  }

  &__description {
    margin-top: pxToRem(30);
    font-size: pxToRem(16);
  }
}
