.login-page {
  margin-top: pxToRem(235);

  &--main-card {
    width: pxToRem(451);
    height: pxToRem(519);

    &__email-input {
      max-height: pxToRem(64);
      .form-group {
        input {
          background-image: none;
        }
      }
    }

    &__form-element {
      max-height: pxToRem(64);
      margin-top: pxToRem(35);
      .form-group {
        input {
          background-image: none;
        }
      }
    }

    &__login-button {
      margin-top: pxToRem(47);
      margin-bottom: pxToRem(30);
    }

    &__lock-icon {
      font-size: pxToRem(20);
    }
  }
}

.restore-password {
  margin-top: pxToRem(235);
  border-radius: pxToRem(8);

  &--card {
    padding-bottom: pxToRem(31);
  }

  &__alert {
    color: $gunmetal;
    font-size: pxToRem(13);
    line-height: 20px;
    margin-bottom: 0;
  }

  &__reset-button {
    margin-top: pxToRem(35);
    height: pxToRem(37);
    width: pxToRem(110);
  }
}

.restore-modal {
  width: pxToRem(501);
  height: pxToRem(374);
  padding: pxToRem(62) pxToRem(52) pxToRem(61) pxToRem(54);

  &__image {
    width: pxToRem(120);
    height: pxToRem(108);
    margin-bottom: pxToRem(32);
  }

  &__text {
    color: $gunmetal;
    font-size: pxToRem(16);
    line-height: pxToRem(25);
    margin-bottom: pxToRem(62);
  }
}

.reset-modal {
  width: pxToRem(501);
  height: pxToRem(267);
  padding: pxToRem(52) pxToRem(45) pxToRem(43) pxToRem(49);

  &__text {
    color: $gunmetal;
    font-size: pxToRem(16);
    margin-bottom: pxToRem(50);
    margin-top: pxToRem(30);
  }
}

.reset-page {
  margin-top: pxToRem(235);

  &--main-card {
    width: pxToRem(451);
    height: pxToRem(463);

    &__form-element {
      max-height: pxToRem(64);
      margin-top: pxToRem(35);
      .form-group {
        input {
          background-image: none;
        }
      }
    }

    &__login-button {
      margin-top: pxToRem(47);
      margin-bottom: pxToRem(30);
    }

    &__lock-icon {
      font-size: pxToRem(20);
    }
  }
}
