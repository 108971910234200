.arrow{
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.date{
    padding-left: 20px;
    padding-right: 20px;
    color: #495057;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
}

.statistics-header{
    padding-top: 24px;
}

.statistics{
    color: #495057;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
}