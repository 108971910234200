.bank-card {
  border-radius: pxToRem(8);

  &__numbers {
    font-size: pxToRem(24);
    font-weight: 600;
    line-height: pxToRem(35);
  }

  &__refund-button {
    width: pxToRem(187);
    margin-top: pxToRem(90);
    margin-bottom: pxToRem(25);
  }
}

.payment-management {
  &__cell-name {
    color: $gunmetal;
    font-size: pxToRem(14);
    font-weight: 500;
    line-height: pxToRem(36);
  }

  &__spinner {
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.highlight-word {
  background-color: $yellow;
}
