.logout-icon {
  font-size: pxToRem(24);
  transform: rotate(180deg);
  margin-left: pxToRem(16);
  padding-left: pxToRem(13);
  cursor: pointer;
}

.logout-modal {
  width: pxToRem(500);
  height: pxToRem(267);
  padding: pxToRem(52) pxToRem(50) pxToRem(43) pxToRem(45);
  display: flex;
  flex-direction: column;
}
